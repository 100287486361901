import axios from 'axios';
// import getToken from './auth';
import baseConfig from './baseConfig';

// const token = getToken();

const service = axios.create({
    baseURL: baseConfig.baseUrl, // url = base url + request url
    timeout: 80000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        config.headers.appType = 'share'
        return config;
    },
    (error) => (Promise.reject(error)),
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        res.code = String(res.code)
        if (res.code !== '1') {
            $('#myModalBody').html(res.message || 'Error');
            $('#myModal').modal();
            return;
            // return alert(res.message || 'Error');
            // return Promise.reject(new Error(res.msg || 'Error'));
        }
        return res;
    },
    (error) => {
        alert(error.message);
        return Promise.reject(error);
    },
);

export default service;
