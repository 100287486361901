import request from "../utils/request";

// 获取用户昵称
export function getUserNick(targetUserId) {
    return request({
        url: `/website/getUserNick?param=${targetUserId}`,
        method: 'get',
    })
}

// 获取钻石列表
export function getPriceList() {
    return request({
        url: `/website/getPriceList`,
        method: 'get',
    })
}

// 下单并获取支付参数
export function createOrder(data) {
    return request({
        url: `/website/createOrder`,
        method: 'post',
        data
    })
}
export function getOrderStatus(tradeNo) {
    return request({
        url: `/website/getOrderStatus?tradeNo=${tradeNo}`,
        method: 'get',
    })
}
