<template>
  <div class="active-page">
    <div class="container">
      <div class="media" v-for="(item,index) in list" :key="index" @click="()=>(clickItem(item))">
        <img v-lazy="item.icon"
             class="align-self-center mr-3 img"
             alt="img">
        <div class="media-body">
          <h5>{{ item.title }}</h5>
          <p class="time">{{ item.time }}</p>
          <p class="mb-0 des">
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from './../assets/images/active/img1.png'
import img2 from './../assets/images/active/img2.png'
import img3 from './../assets/images/active/img3.png'

export default {
  name: "RecentActivities",
  data() {
    return {
      list: [
        {
          title: '健康游戏忠告',
          time: '2022-03-01',
          content: '抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活',
          icon: img1,
          id: 0,
        },
        {
          title: '剧本杀审核标准',
          time: '2022-03-01',
          content: '为了引导青少年合理使用网络，呵护未成年人健康成长，营造风清气正的网络环境，尽全力为用户提供更加规范、安全、优质的服务…',
          icon: img2,
          id: 1,
        },
        {
          title: '新本上架',
          time: '2022-03-01',
          content: '唐天舞二十六年，二月初七，正值春闱，全国的考生纷纷赶来长安城参加会试，平静的长安城也随之忙碌起来。然而今天晚上…',
          icon: img3,
          id: 2,
        },
        {
          title: '新本上架',
          time: '2022-03-01',
          content: '唐天舞二十六年，二月初七，正值春闱，全国的考生纷纷赶来长安城参加会试，平静的长安城也随之忙碌起来。然而今天晚上…',
          icon: img3,
          id: 3,
        },
      ]
    }
  },
  methods: {
    clickItem({id, title}) {
      this.$router.push(`/activitiesDetail/${id}?title=${title}`)
    }
  }
}
</script>

<style scoped>
.active-page {
  background-image: url("./../assets/images/bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  padding-bottom: 1.667vw;
  padding-top: 6.083vw;
}

.media {
  margin: 1.917vw 2.5vw;
  zoom: 0.8;
}

.media-body {
  margin-top: 0.417vw;
}

.img {
  width: 43.583vw;
  height: 13.833vw;
  border-radius: 0.833vw;
  margin-right: 2.083vw !important;
}

h5 {
  font-size: 33px;
  font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  line-height: 3.583vw;
  margin-bottom: 0.917vw;
}

.time {
  font-size: 15px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.75vw;
  margin-bottom: 16px;
}

.des {
  width: 47.25vw;
  height: 4.833vw;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 3vw;
}
</style>
