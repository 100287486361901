<template>
  <div class="active-detail page-container">
    <div class="active-detail-card container">
      <div class="header">
        <img :src="article.img">
        <p class="title">{{ article.title }}</p>
      </div>
      <div class="content">
        <div style="white-space: pre-wrap;" v-html="article.content"/>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from './../assets/images/active/detail2.png'
import img2 from './../assets/images/active/detail1.png'
import img3 from './../assets/images/active/detail3.png'

export default {
  name: "RecentActivitiesDetail",
  data() {
    return {
      info: {
        '0': {
          img: img1,
          content: `<div style="text-align: center">            抵 制 不 良 游 戏，拒 绝 盗 版 游 戏。
            注 意自 我 保 护，谨 防 受 骗 上 当。
            适 度 游 戏 益 脑，沉 迷 游 戏 伤 身。
            合 理 安 排 时 间，享 受 健 康 生 活。</div>`,
        },
        '1': {
          img: img2,
          content: `<div  style="text-indent: 40px">为了引导青少年合理使用网络，呵护未成年人健康成长，营造风清气正的网络环境，尽全力为用户提供更加规范、安全、优质的服务，以及健康向上的剧本杀体验，为此我们推出了《剧本杀审核标准》（以下简称“审核标准”），希望内容生产者都能按照此标准进行创作，坚守住行业的发展底线，用正确的价值导向来引导用户。剧阵APP有权根据法律法规、监管政策、行业规范以及经营策略调整变化，对本审核标准进行变更、中止和终止，并在相关页面（包括但不限于公众号，APP弹窗等）进行公布。
具体标准如下：
1.作品需符合社会主义核心价值观，坚持正确的政治方向、舆论导向、价值取向，不得写法律法规禁止传播的内容；
2.不得抄袭他人作品、剽窃他人创意、借鉴他人诡计手法等行为。范围包括任何影视作品、游戏、小说及漫画中出现的推理诡计、剧情故事核心走向、密码谜题等。一经发现抄袭诡计，盗用作品者，之后投稿将一律不再采用，且作者的信用将被挂上黑名单！
3.不得含有反对国家政权、歪曲我国各项政治制度的内容；
4.不得含有涉及分裂国家、反人类反社会等其他三观不正、违背公序良俗的内容情节；
5.不得含有歪曲、丑化中国优秀传统文化和英雄人物的内容；
6.不得描述或影射现实政治相关的内容，不得设置影射、丑化国家的内容；
7.不得否定英雄烈士、伤害民族感情和国家荣誉；
8.不得宣扬民族仇恨、民族歧视、人群歧视、地域歧视和恐怖主义、极端主义的内容；
9.不得宣扬和展现历史虚无主义，不得恶意攥改重大历史事实，不得恶意攥改重大历史事件发生的朝代和时间，不得歪曲中华文明和中国历史，不得有意歪曲、丑化历史人物，不得颠覆历史人物的真实形象。对于历史上公认的忠臣和奸臣不得随意歪曲和调换历史形象。如创作、设计可能对历史人物认知产生误导，可在主题中显著位置对角色的历史原型进行解释说明；
10.不得出现境外武装力量以反恐名义攻击我国目标的行为，或从事侵犯我国主权和领土完整的活动；不宜宣扬、鼓吹大规模杀伤性武器、生化武器、核武器、枪支、管制工具（作为武器用）、尖锐物（作为武器）等的作用和效果，不宜刻意渲染大规模杀伤的恐怖场景；
11.不得不当评述灾难；
12.不得含有恐怖的情节和内容，不宜出现过于渲染恐怖氛围的情节和内容；
13.不得含有过于血腥、惊悚、残忍、暴力等可能致人身心不适的内容。不宜清晰表现伤害过程和鲜血喷涌、血流成河、血流满地等场景；不宜表现残缺人体或人体被分解（断肢）等情形；不宜刻意表现曝尸、肢解尸体、虐尸等残忍行为；
14.不得含有色情、低俗内容或易使人产生性联想的内容情节；
15.不得表现淫乱、强奸、卖淫、嫖娼、一夜情、换妻、性虐待等行为的手段、过程和细节。不得以任何形式出现“儿童色情”、“儿童性侵”、“恋童癖”等内容；
16.不得出现详细的吸毒、赌博描写；
17.不得鼓吹或教唆犯罪、自杀和不安全行为；不宜出现表现自杀、自残行为的内容情节；不得存在教唆、怂恿实施自杀、自残行为的内容情节；
18.不得在剧本内对玩家进行负面心理暗示，不宜渲染悲观厌世、抑郁等负面情绪；
19.不得宣扬、鼓吹邪教或者宣扬封建迷信内容；
20.不得含有灵异的情节和内容（古代玄幻本可以有灵异成分，比如仙妖，但不得含有鬼怪、僵尸、丧尸等）；
21.不得侮辱或者诽谤现实团体或个人，或者散布和传播谣言；
22.不得含有未成年人犯罪以及对未成年人犯罪的情节，禁止含有未成年、婴幼儿死者；
23.不得出现对未成年人实施家庭暴力、虐待、遗弃的情节和内容；不宜出现未成年人吸烟、酗酒、流浪、以及赌博、吸毒、卖淫等行为的情节和内容；不宜出现未成年人购买烟、酒和其他违禁物品的情节和内容；不宜出现会使未成年人模仿导致危害其人身安全的内容；
24.不宜出现详细呈现捆绑及殴打、虐待过程及暴力对待过程的内容，不宜展现过多暴力行为，以免误导未成年人对暴力行为的认知，不宜呈现残忍虐待动物的情节；
25.关于背景音乐、角色配音和道具配音等，不宜出现营造过度阴森、恐怖、惊悚效果的音效、对人类或动物进行虐待的音效，以及刻意渲染暴力、打斗、撕扯、呻吟、尖叫等的音效；
26.不得含有其他违法违规和不适宜传播的内容。
我们会根据实际情况实时调整本审核标准，以和内容生产者一起坚守住行业发展的底线，维护住行业健康的发展环境。</div>
`
        },
        '2': {
          img: img3,
          content: `唐天舞二十六年，二月初七，正值春闱，全国的考生纷纷赶来长安城参加会试，平静的长安城也随之忙碌起来。
            然而今天晚上，长安城的悦来客栈出了一起命案。一名考生在房间内上吊自杀吃，这个考生乃是赵凭，当朝重臣赵谨言的儿子，而且还是这次春闱的大热门，极大可能是这次的状元郎，不知为何作此了断。这让许多人纷纷想起三年前的案子，同样是一位大热门的考生，同样在二月初八，同样吊死在这悦来客栈。看来，这桩案子不仅仅是自杀这么简单……
            [详情请前往剧阵app中查看]`
        },
        '3': {
          img: img3,
          content: `唐天舞二十六年，二月初七，正值春闱，全国的考生纷纷赶来长安城参加会试，平静的长安城也随之忙碌起来。
            然而今天晚上，长安城的悦来客栈出了一起命案。一名考生在房间内上吊自杀吃，这个考生乃是赵凭，当朝重臣赵谨言的儿子，而且还是这次春闱的大热门，极大可能是这次的状元郎，不知为何作此了断。这让许多人纷纷想起三年前的案子，同样是一位大热门的考生，同样在二月初八，同样吊死在这悦来客栈。看来，这桩案子不仅仅是自杀这么简单……
            [详情请前往剧阵app中查看]`
        },
      },
      article: {}
    }
  },
  mounted() {
    const {id, title} = this.$route.params
    this.article = {
      title,
      ...this.info[String(id)],
    }
  }
}
</script>

<style scoped>
.active-detail {
  background-image: url("./../assets/images/bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  min-height: 100vh;
}

.active-detail-card {
  padding-top: 3.333vw;
  zoom: 0.8;
  /*padding: 3.333vw 5vw;*/

}

.active-detail-card .header {
  width: 100%;
  height: 27%;
  border-radius: 1.667vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.417vw;
}

.header img {
  width: 100%;
}

.active-detail-card .title {
  position: absolute;
  z-index: 7;
  font-size: 58px;
  font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  line-height: 6.333vw;
  leer-spacing: 1px;
}

.active-detail-card .content {
  font-size: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 3vw;
  leer-spacing: 1px;
  padding-bottom: 1.667vw;
}
</style>
