import Loading from './Loading'
import Vue from 'vue'


let instance;
let LoadingConstructor = Vue.extend(Loading);
let seed = 1;
let index = 2000;

const install = () => {
    Object.defineProperty(Vue.prototype, '$Loading', {
        get() {
            let id = 'loading_' + seed++
            const loadingMsg = options => {
                instance = new LoadingConstructor({
                    propsData: options
                })
                index++;
                instance.id = id
                instance.vm = instance.$mount();
                document.body.appendChild(instance.vm.$el);
                instance.vm.$el.style.zIndex = index;
                // 移除当前的dom
                const close = () => {
                    document.body.removeChild(instance.vm.$el);
                }
                return {
                    close,
                    vm: instance.vm,
                };
            }
            return loadingMsg
        }
    })
}

export default install;
