<template>
  <div v-show="visible" class="dialog-card">
    <div class="dialog-content">
      <div class="dialog-head">
        <h5>{{ title }}</h5>
        <img src="./../assets/images/close.png" alt="关闭" @click="onClose">
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    onClose: null,
  },
  methods: {}
}
</script>

<style scoped>
.dialog-card {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
}

.dialog-head {
  position: relative;
}

.dialog-head h5 {
  font-size: 2.5vw;
  font-family: YouSheBiaoTiHei;
  color: #000000;
  line-height: 3.917vw;
  letter-spacing: 4px;
  text-align: center;
}

.dialog-head img {
  cursor: pointer;
  width: 1.667vw;
  height: 1.667vw;
  /*width: 1.667vw;*/
  /*height: 1.667vw;*/
  position: absolute;
  right: 0;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  /*top: 0.417vw;*/
}

.dialog-content {
  min-width: 25vw;
  position: fixed;
  /*padding: 2.75vw 2.333vw;*/
  padding: 2.333vw;
  border-radius: 0.667vw;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 2.333vw;
  letter-spacing: 2px;
  z-index: 210;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-body {
  text-align: center;
  color: #000000;
  line-height: 1.917vw;
  letter-spacing: 2px;
  font-size: 17px;
}

</style>
