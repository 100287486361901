import Vue from 'vue'
import App from './App.vue'
import './assets/css/fonts.css'
import './assets/css/reset.css'
import './assets/css/common.css'
import router from './router';
import Router from 'vue-router'
import Loading from './plugin/Loading';
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(Router)
Vue.use(Loading);
Vue.use(VueLazyload);

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
