<template>
  <div class="news-page">
    <div class="container">
      <div class="media" v-for="(item,index) in list" :key="index" @click="()=>(clickItem(item))">
        <img v-lazy="item.icon"
             class="align-self-center mr-3 img"
             alt="img">
        <div class="media-body">
          <h5>{{ item.title }}</h5>
          <p class="time">{{ item.time }}</p>
          <p class="mb-0 des">
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>

    <div class="dl">
        <div style="color:red;height:30px; margin: auto;text-align:center;font-size: 15px;">(行业资讯为付费内容，1元/条)</div>
          <div class="textbox">
           <img src="./../assets/images/active/11.jpg" style="width:300px;">
          <a href="javascript:void(0);" style="display: block;float: left;margin-top: 25px;text-align: center;width: 100%;font-size: 14px;color: #888;" class="gb"  onclick="closeAction()">[关闭]</a>
      </div>
    </div>
  </div>
</template>

<script>
import news1 from './../assets/images/active/news1.png'
import news2 from './../assets/images/active/news2.png'
import news3 from './../assets/images/active/news3.png'
import news4 from './../assets/images/active/news4.png'
import news5 from './../assets/images/active/news5.png'
import news6 from './../assets/images/active/news7.jpeg'


export default {
  name: "News",
  data() {
    return {
      list: [
        {
          title: '北京发文加强“剧本杀”“密室逃脱”等娱乐场所消防设计质量管理',
          time: '2023-08-04',
          content: '据新京报讯，8月4日，北京市规划和自然资源委员会官网发布关于加强剧本娱乐经营场所消防设计质量管理的通知。其中强调，“剧本杀”“密室逃脱”等娱乐经营场所，消防设计应严格执行现行消防技术标准且不低于歌舞娱乐放映游艺场所的相关要求',
          icon: news1,
          id: 0,
        },
        {
          title: '蒲熠星监制首部剧本杀《长安夜放花千树》授权名单',
          time: '2023-08-01',
          content: '8月1日，由蒲熠星出资并担任内容总监的剧本杀工作室“火羽白”在微博上公布了《长安夜放花千树》最新的正版授权门店名单。据悉，《长安夜放花千树》是工作室发行的首部作品，也是阿蒲在剧本杀领域的开山之作，经历了长时间的打磨后，终于面世。',
          icon: news2,
          id: 1,
        },
        {
          title: '《黑日》要下架了？作者回应：盗版要全面下架',
          time: '2023-08-01',
          content: '近来，有网友在小红书热门笔记下评论：“怎么有人说《黑日》要下架了？”。对此，《黑日》的作者魏蜀黍于8月1日在其小红书账号的评论区回应网友，作出澄清。他表示，“说的是盗版要全面下架了，正版已经过文旅局审核的，不会下架。”',
          icon: news3,
          id: 2,
        },
        {
          title: '西宁一剧本杀体验馆被举报含色情剧本，文旅局已前往调查',
          time: '2023-07-26',
          content: '近日，有网友发文称，青海省西宁市城中区一剧本杀体验馆涉黄。7月26日，该网友告诉澎湃新闻，针对上述经历，她们当日已经向12345市民服务热线进行投诉。随后，有民警通过电话告知她，辖区派出所、文化旅游局、',
          icon: news4,
          id: 3,
        },
        {
          title: '别让剧本杀变成“消失的它”！杭州市剧本娱乐协会成立',
          time: '2023-07-25',
          content: '7月25日，由杭州市文化广电旅游局牵头指导建设的杭州市剧本娱乐协会第一届会员大会暨成立大会在杭州召开。据杭州应急管理此前消息，5月起各区、县（市）文化和旅游行业管理部门、杭州市剧本娱乐协会',
          icon: news5,
          id: 4,
        },
        {
          title: '上海首例盗版“剧本杀”侵犯著作权案宣判，9人团伙获刑',
          time: '2023-06-25',
          content: '6月25日，上海首例盗版“剧本杀”侵犯著作权案获判。该案因涉案金额巨大、涉案人数多等因素，广受社会关注。最终，法院判处苏某某有期徒刑四年六个月并处罚金，与其犯非法制造、销售非法制造的注册商标标识罪数罪并罚，判处有期徒刑六年',
          icon: news6,
          id: 5,
        }
      ]
    }
  },
  methods: {
    clickItem({id, title}) {
      // this.$router.push(`/activitiesDetail/${id}?title=${title}`)
      $('.dl').show();
    }
  }
}

$(document).ready(function(){
  // $('.gb').click(function() {
  //   console.log("执行了关闭的方法");
  //   $('.dl').hide();
  // })
  function closeAction() {
  $('.dl').hide();
}
window.closeAction = closeAction;
});



</script>

<style scoped>
.news-page {
  background-image: url("./../assets/images/bg.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  padding-bottom: 1.667vw;
  padding-top: 6.083vw;
}

.media {
  margin: 1.917vw 2.5vw;
  zoom: 0.8;
}

.media-body {
  margin-top: 0.417vw;
  height: auto;
}

.img {
  width: 43.583vw;
  height: 13.833vw;
  border-radius: 0.833vw;
  margin-right: 2.083vw !important;
}

h5 {
  font-size: 30px;
  font-family: YouSheBiaoTiHei;
  color: #FFFFFF;
  line-height: 2.583vw;
  margin-bottom: 0.917vw;
}

.time {
  font-size: 15px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1vw;
  margin-bottom: 16px;
}

.des {
  width: 47.25vw;
  height: 4.833vw;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 2vw;
}

.dl {
			width: 300px;

			background: #fff;
			border: solid 1px #eee;
			position: fixed;
			left: 50%;
			top: 50%;
			margin-left: -180px;
			margin-top: -150px;
			display: none;
		}
		
		.dl p {
			text-align: center;
			background: #64aed9;
			height: 48px;
			line-height: 48px;
			color: #fff;
			font-size: 24px;
			box-shadow: 2px 2px 5px #aaa;
			font-family: "微软雅黑";
			margin-bottom: 25px;
		}
		
		.textbox {
			height: 32px;
			margin-bottom: 30px;
		}
		
		.textbox label {
		
			height: 32px;
			display: inline-block;
			padding-left: 50px;
			font-size: 16px;
		}
		
		.textbox .text {
			display: inline-block;
			height: 24px;
			width: 200px;
		}
		
		.btnd {
			width: 220px;
			height: 40px;
			display: block;
			margin-left: 90px;
			background: #64aed9;
			line-height: 40px;
			text-align: center;
			font-size: 18px;
			color: #fff;
		}


</style>
